<template>
  <div class="contacts-wrap">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title has-text-centered">
        <h3 class="title is-1 pt-6 txt-white">
          ¿Necesitas ayuda?
        </h3>
        <p class="txt-white title is-4">
          ¿Está interesado en unirte a la plataforma o tiene problemas con un pedido? Envíanos un mensaje y haremos todo lo posible para ayudarte.
        </p>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="contact-form">
          <div class="columns content-contact-form">
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre*'" v-model="person_first_name" :name="'person_first_name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Apellidos*'" v-model="person_last_name" :name="'person_last_name'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Email*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Teléfono'" v-model="phone" :name="'phone'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Razón Social'" v-model="name" :name="'name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Numero de pedido (si disponible)'" v-model="orderNumber" :orderNumber="'orderNumber'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>Categoría*</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>Método de contacto favorito</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>

                <div class="column is-12 mb-3">
                  <!-- <textarea></textarea> -->
                  <div class="message-wrap">
                    <p><strong class="is-uppercase">Mensaje*</strong></p>
                    <p>
                      Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vestibulum id ligula porta felis euismod semper. Donec sed odio dui. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit.
                    </p>
                  </div>
                </div>

                <div class="column is-12 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/contacts')">Enviar mensaje</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
export default {
  name: 'ClinicPersonalInfo',
  components: {
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      email: '',
      name: '',
      phone: '',
      person_first_name: '',
      person_last_name: '',
      orderNumber: ''
    }
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.contacts-wrap{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -260px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .contact-form{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-contact-form{
        padding: 80px;
        textarea, .message-wrap{
          width: 100%;
          box-sizing: border-box;
          height: 250px;
          border: 1px solid rgba(87,86,124,0.5);
          border-radius: 4px;
        }
        .message-wrap{
          // height: auto;
          padding: 16px;
        }
      }
    }
  }
}
</style>
